import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { isAuth, getUserStatus, getUserFirstName, getUserLastName } from 'containers/authorization/selectors';
import { logout } from 'containers/authorization/actions';
import Logo from 'assets/svg/FintoolBlack';
import SearchBar from '../SearchBar';

import { getMobileMenu } from './utils';
import { Container, Navbar, LogoContainer, NameContainer, P, List } from './styles';
import Button from './Button';
import Item from './Item';

const MobileHeader = ({ resetSearch }) => {
  const { t } = useTranslation('app');
  const [isSignedIn, status, name, surName] = [
    useSelector(isAuth),
    useSelector(getUserStatus),
    useSelector(getUserFirstName),
    useSelector(getUserLastName),
  ];
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClick = useCallback(
    (e) => {
      if (e && e.currentTarget.dataset.path === '/videos') resetSearch();
      setOpen(!open);
    },
    [open, resetSearch]
  );
  const handleCloseMenu = useCallback(() => setOpen(false), []);
  const handleLogout = useCallback(() => {
    dispatch(logout());
    handleClick();
  }, [dispatch, handleClick]);

  const list = useMemo(() => getMobileMenu(isSignedIn, status), [isSignedIn, status]);

  useEffect(() => {
    if (!open) return () => null;

    const html = document.querySelector('html');
    html.style.overflow = 'hidden';

    return () => {
      html.style.overflow = 'auto';
    };
  }, [open]);

  return (
    <Container open={open}>
      <Navbar open={open}>
        <LogoContainer>
          <Link to="/">
            <Logo />
          </Link>
        </LogoContainer>
        <NameContainer to="/account" onClick={handleCloseMenu}>
          <P>{name}</P>
          <P>{surName}</P>
        </NameContainer>
        <Button isOpen={open} onClick={handleClick} />
      </Navbar>
      <SearchBar />
      <List $isOpen={open}>
        {list.map(({ key, name: btnName, link, matches, isHightLight, isExternal }) =>
          key === 'logout' ? (
            <Item key={key} name={t(btnName)} onClick={handleLogout} />
          ) : (
            <Item
              key={key}
              name={t(btnName)}
              onClick={handleClick}
              to={link}
              matches={matches}
              isHightLight={isHightLight}
              isExternal={isExternal}
            />
          )
        )}
      </List>
    </Container>
  );
};

MobileHeader.propTypes = { resetSearch: PropTypes.func.isRequired };

export default MobileHeader;
