export const EN = 'en';
export const DE = 'de';
export const FR = 'fr';
export const IT = 'it';

export const DEFAULT_LANGUAGE = DE;

const langList = [EN, DE, FR, IT];
export const LANG_LIST = process.env.REACT_APP_ENV === 'production' ? [DE] : langList;
export const ALLOW_CHANGE_LANGUAGE = process.env.REACT_APP_ENV !== 'production';

export const LOCALE_LIST = [
  'errors',
  'courses',
  'app',
  'account',
  'assessments',
  'authorization',
  'posts',
  'post',
  'program',
  'player',
  'lectures',
  'events',
  'course',
  'books',
  'compendio',
  'support',
];

export const LANG_LABELS = [
  {
    id: EN,
    name: 'English',
  },
  {
    id: DE,
    name: 'Deutsch',
  },
  {
    id: FR,
    name: 'Français',
  },
  {
    id: IT,
    name: 'Italiano',
  },
];
