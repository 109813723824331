export default {
  font70: `
    font-family: "Okomito-Regular", sans-serif;
    font-size: 70px;
    font-weight: 300;
    line-height: 1.14;
  `,
  large: `
    font-family: "Okomito-Regular", sans-serif;
    font-size: 43px;
    font-weight: normal;
    line-height: 1.19;
    color: var(--color-black);
  `,
  title: `
    font-family: "Okomito-Regular", sans-serif;
    letter-spacing: normal;
    font-weight: normal;
    color: var(--color-black);
    font-size: 32px;
    line-height: 1.22;
  `,
  subtitle: `
    font-family: "Okomito-Regular", sans-serif;
    color: var(--color-black);
    font-size: 26px;
    line-height: 1.23;
    font-weight: normal;
    @media (max-width: 1023px) {
      font-size: 21px;
      line-height: 1.29;
      letter-spacing: 0.36px;
    }
  `,
  font26: `
    font-family: "Okomito-Regular", sans-serif;
    color: var(--color-black);
    font-size: 26px;
    line-height: 1.23;
    font-weight: normal;
  `,
  subtitleBold: `
    font-family: "Okomito-Medium", sans-serif;
    color: var(--color-black);
    font-size: 26px;
    line-height: 1.35;
    font-weight: 500;
    @media (max-width: 1023px) {
      font-size: 21px;
      line-height: 1.29;
      letter-spacing: 0.36px;
    }
  `,
  regularLarge: `
    font-family: "Okomito-Regular", sans-serif;
    font-size: 21px;
    line-height: 1.29;
    letter-spacing: 0.36px;
    font-weight: normal;
    color: var(--color-black);
  `,
  regularLargeBold: `
    font-family: "Okomito-Medium", sans-serif;
    font-size: 21px;
    line-height: 1.29;
    letter-spacing: 0.36px;
    font-weight: normal;
    color: var(--color-black);
  `,
  font20: `
    font-family: "Okomito-Regular", sans-serif;
    font-size: 20px;
    line-height: 1;
    letter-spacing: 0.5px;
    font-weight: normal;
  `,
  regular: `
    font-family: "Okomito-Regular", sans-serif;
    font-size: 18px;
    line-height: 1.28;
    letter-spacing: 0.31px;
    font-weight: normal;
    color: var(--color-black);
  `,
  regularBold: `
    font-family: "Okomito-Medium", sans-serif;
    font-size: 18px;
    line-height: 1.28;
    letter-spacing: 0.4px;
    font-weight: 500;
    color: var(--color-black);
  `,
  regularSmall: `
    font-family: "Okomito-Regular", sans-serif;
    font-size: 15px;
    line-height: 1.4;
    letter-spacing: 0.26px;
    font-weight: normal;
    color: var(--color-black);
  `,
  small: `
    font-family: "Okomito-Regular", sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.18px;
    color: var(--color-black);
  `,
  smallBold: `
    font-family: "Okomito-Medium", sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.5px;
    color: var(--color-black);
  `,
};
